import React, { useState, useEffect } from "react";
import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import "../hero.css"; // Custom CSS for animations and font
import SubjectsBanner from "./SubjectsBanner"; // Adjust the path based on where the component is located
import Navbar from "./Navbar";
const Hero = () => {
  const quizzes = [
    {
      clue: "Mathematics: Solve for x in 2x + 3 = 7",
      answer: "2",
    },
    {
      clue: "Mathematics: Area of a circle = π × radius ______",
      answer: "SQUARED",
    },
    {
      clue: "Physics: The unit of electric current",
      answer: "AMPERE",
    },

    {
      clue: "Biology: The basic unit of life",
      answer: "CELL",
    },
    {
      clue: "Chemistry: Symbol for Gold",
      answer: "AU",
    },
    {
      clue: "Geography: Largest lake in Africa",
      answer: "VICTORIA",
    },
    {
      clue: "History: First president of Uganda",
      answer: "MILTON OBOTE",
      image: "/images/obote.png",
    },
    {
      clue: "SST: Capital city of Uganda",
      answer: "KAMPALA",
    },
    {
      clue: 'English: Opposite of "Include"',
      answer: "EXCLUDE",
    },
    {
      clue: "Chemistry: pH scale measures ______",
      answer: "ACIDITY",
    },
    {
      clue: "Mathematics: The value of π (pi) to two decimal places",
      answer: "3.14",
    },
    {
      clue: "Chemistry: The gas produced during photosynthesis",
      answer: "OXYGEN",
    },
  ];

  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [displayedLetters, setDisplayedLetters] = useState([]);
  const [charIndex, setCharIndex] = useState(0);
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    if (typing && charIndex < quizzes[currentQuizIndex].answer.length) {
      setTimeout(() => {
        setDisplayedLetters((prev) => [
          ...prev,
          quizzes[currentQuizIndex].answer[charIndex],
        ]);
        setCharIndex(charIndex + 1);
      }, 300); // Adjust typing speed here
    } else if (
      typing &&
      charIndex === quizzes[currentQuizIndex].answer.length
    ) {
      setTyping(false);
      setTimeout(() => {
        setTyping(true);
        setDisplayedLetters([]);
        setCharIndex(0);
        setCurrentQuizIndex((prev) => (prev + 1) % quizzes.length);
      }, 4000); // Delay before switching to the next quiz
    }
  }, [charIndex, typing, quizzes, currentQuizIndex]);

  const currentQuiz = quizzes[currentQuizIndex];

  return (
    <>
      {/* Top Menu (Fixed) */}

      <Navbar />

      {/* Main Hero Section */}
      <div
        className="min-h-screen bg-cover bg-center text-white flex flex-col justify-center items-center pt-24 relative"
        style={{ backgroundImage: `url(images/bg.png)` }} // Background image path
      >
        {/* Content Section */}
        <div className="container mb-8 mx-auto flex flex-col md:flex-row justify-between items-center px-4 space-y-8 md:space-y-0">
          {/* Left - Animated Crossword Effect */}
          <div className="text-center md:text-left max-w-lg">
            {/* Subheading */}
            <h2 className="text-lg md:text-xl font-light mb-4">
              Learn, Play, Succeed, turn every challenge into a game
            </h2>

            {/* Clue inside a speech bubble */}
            <div className="speech-bubble relative bg-white text-black p-6 rounded-lg inline-block flex items-center space-x-4">
              {/* Clue Text */}
              <h1 className="text-2xl md:text-4xl font-bold leading-tight">
                {currentQuiz.clue}
              </h1>

              {/* Clue Image */}
              {currentQuiz.image && (
                <img
                  src={currentQuiz.image}
                  alt="Clue Image"
                  className="h-20 rounded-md"
                />
              )}
            </div>

            {/* Animated Answer */}
            <div className="mt-4 flex justify-center md:justify-start flex-wrap">
              {currentQuiz.answer.split("").map((letter, index) => (
                <div
                  key={index}
                  className={`crossword-square ${
                    letter === " " ? "blank" : ""
                  }`}
                >
                  <span className="crossword-letter">
                    {letter === " " ? "" : displayedLetters[index] || ""}
                  </span>
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="mt-8">
              {/* Play Now Button */}
              <a
                href="https://students.mulmet.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-secondary-orange hover:bg-orange-600 text-white font-semibold py-3 px-6 rounded-lg mr-4">
                  Play Now
                </button>
              </a>

              {/* Teach at Kate-D Button */}
              <a
                href="https://forms.gle/7DAyhRPWVeXCMapx6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border-2 border-secondary-orange text-orange-500 hover:bg-orange-600 hover:text-white font-semibold py-3 px-6 rounded-lg">
                  Teach at Kate-D
                </button>
              </a>
            </div>
          </div>

          {/* Right - Image */}
          <div className="mt-8 md:mb-8 md:mt-0">
            <img
              src="/images/ninja.gif" // Image path
              alt="Educational Illustration"
              className="w-72 h-72 md:w-96 md:h-96 rounded-full object-cover"
            />
          </div>
        </div>
        {/* Subjects Banner at the Bottom */}
        <div className="w-full mt-8  bottom-0">
          <SubjectsBanner />
        </div>
      </div>
    </>
  );
};

export default Hero;
