import React, { useState, useEffect } from "react";
import { Bars3Icon, XMarkIcon, UserIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home"); // Track active link
  const [isScrolled, setIsScrolled] = useState(false); // To track scroll position

  // Function to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true); // Set to true if user scrolls down more than 50px
      } else {
        setIsScrolled(false); // Reset when user scrolls back up
      }
    };

    window.addEventListener("scroll", handleScroll); // Add event listener for scroll

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up event listener
    };
  }, []);

  return (
    <nav
      className={`fixed w-full z-10 top-0 transition-all duration-300 ${
        isScrolled ? "bg-primary-blue" : "bg-transparent"
      }`} // Add background color when scrolled
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src="./images/logo/kated.svg"
            alt="Kate-D Logo"
            className="w-16 h-16 mr-2"
          />
        </div>

        {/* Desktop Menu */}
        <div className="hidden text-white md:flex space-x-6 items-center">
          {/* Links */}
          {[
            "Home",
            "Resources",
            "Students",
            "Teachers",
            "Schools",
            "Contact Us",
          ].map((item) => (
            <Link
              key={item}
              to={`#${item.toLowerCase().replace(/ /g, "-")}`}
              className={`hover:text-secondary-orange ${
                activeLink === item ? "border-b-2 rounded-sm border-white" : ""
              }`}
              onClick={() => setActiveLink(item)}
            >
              {item}
            </Link>
          ))}

          {/* Login Button */}
          <div className="flex items-center ml-auto">
            <a
              href="https://students.mulmet.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="flex items-center border-2 border-secondary-orange text-secondary-orange px-6 py-1 rounded-md hover:bg-secondary-orange hover:text-white transition duration-300"
              >
                <UserIcon className="w-5 h-5 mr-2" />
                Login
              </button>
            </a>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <XMarkIcon className="h-8 w-8 text-white" />
            ) : (
              <Bars3Icon className="h-8 w-8 text-white" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-primary-blue text-white px-4 py-4 space-y-4">
          {[
            "Home",
            "Resources",
            "Students",
            "Teachers",
            "Schools",
            "Contact Us",
          ].map((item) => (
            <a
              key={item}
              href={`#${item.toLowerCase().replace(/ /g, "-")}`}
              className="block hover:text-secondary-orange"
              onClick={() => {
                setIsOpen(false);
                setActiveLink(item);
              }}
            >
              {item}
            </a>
          ))}

          {/* Dropdown for Login */}
          <div className="relative">
            <a
              href="https://students.mulmet.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="flex items-center border-2 border-secondary-orange text-secondary-orange px-6 py-1 rounded-md hover:bg-secondary-orange hover:text-white transition duration-300"
              >
                <UserIcon className="w-5 h-5 mr-2" />
                Login
              </button>
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
