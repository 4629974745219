import React from 'react';
import './SubjectsBanner.css'; // Custom CSS for carousel animation

// List of subjects and corresponding image file names
const subjects = [
  { name: 'Agriculture', icon: 'images/icons/agriculture.svg' },
  { name: 'Art & Design', icon: 'images/icons/art.svg' },
  { name: 'Biology', icon: 'images/icons/biology.svg' },
  { name: 'Chemistry', icon: 'images/icons/chemistry.svg' },
  { name: 'Entreprenuership', icon: 'images/icons/commerce.svg' },
  { name: 'Computer', icon: 'images/icons/computer.svg' },
  { name: 'CRE', icon: 'images/icons/cre.svg' },
  { name: 'English', icon: 'images/icons/english.svg' },
  { name: 'Food & Nutrition', icon: 'images/icons/food.svg' },
  { name: 'Geography', icon: 'images/icons/geography.svg' },
  { name: 'History & Political Education', icon: 'images/icons/history.svg' },
  { name: 'Islam', icon: 'images/icons/islam.svg' },
  { name: 'Kiswahili', icon: 'images/icons/kiswahili.svg' },
  { name: 'Literature', icon: 'images/icons/literature.svg' },
  { name: 'Mathematics', icon: 'images/icons/math.svg' },
  { name: 'Physics', icon: 'images/icons/physics.svg' },
];

const SubjectsBanner = () => {
  return (
    <div className="subjects-banner-wrapper">
      <div className="subjects-banner">
        <div className="subjects-track">
          {subjects.map((subject, index) => (
            <div key={index} className="subject-item flex items-center space-x-2">
              <img src={subject.icon} alt={subject.name} className="w-12 h-12" />
              <p className="text-white text-center">{subject.name}</p>
            </div>
          ))}
          {/* Duplicate the subjects multiple times for continuous scrolling */}
          {subjects.map((subject, index) => (
            <div key={`duplicate-${index}`} className="subject-item flex items-center space-x-2">
              <img src={subject.icon} alt={subject.name} className="w-8 h-8" />
              <p className="text-white text-center">{subject.name}</p>
            </div>
          ))}
          {subjects.map((subject, index) => (
            <div key={`duplicate2-${index}`} className="subject-item flex items-center space-x-2">
              <img src={subject.icon} alt={subject.name} className="w-8 h-8" />
              <p className="text-white text-center">{subject.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubjectsBanner;
